<template>
    <!-- When in doubt, consult the Vuetify documentation for v-data-table with CRUD actions. Link below: -->
    <!-- https://vuetifyjs.com/en/components/data-tables/#crud-actions -->

    <v-layout v-resize="onResize">
        <v-data-table data-testid="znap-table"
            :headers="headers"
            :items="serverSidePaginationSearch ? serverSideFilteredItems : apiResult.items"
            :footer-props="serverSidePagination ? {} : footerProps"
            :items-per-page="itemsPerPage"
            @current-items="getFiltered"
            :page.sync="pagination.page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :hide-default-footer="serverSidePagination"
            @pagination="updatePage"
            :server-items-length="serverSidePagination ? serverSideItemsLength : -1"
            :item-key="getItemKey()"
            :show-select="readOnly ? false : true"
            :search="searchProp ? searchProp : search"
            :custom-filter="customFilter"
            :loading="loading"
            loading-text="Carregando dados..."
            v-model="selected"
            class="elevation-1 text-no-wrap"
            :height="noData ? '' : windowSize.y - 64 - 157 - hasOptions"
            fixed-header
            style="width: 100%;"
        >
            <template v-slot:top>
                <v-toolbar flat class="rounded-lg" v-if="showToolbar">
                    <div class="d-flex align-start mr-4">
                        <v-icon x-large color="primary">
                            {{ tableIcon }}
                        </v-icon>
                    </div>
                    <div class="d-flex flex-column align-start">
                        <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                        <!-- <div class="mb-2 d-flex align-center">
                            <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                            <span class="text-caption">{{ tableDescription }}</span>
                        </div> -->
                    </div>

                    <v-spacer></v-spacer>

                    <template v-if="!serverSidePagination">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$vuetify.lang.t('$vuetify.searchLabel')"
                            hide-details
                            single-line
                            dense
                            clearable
                            class="mr-4"
                            style="width: 1%;"
                        />
                    </template>

                    <template v-else>
                        <v-text-field
                            v-model="serverSidePaginationSearch"
                            :label="serverSideProps.label"
                            hide-details
                            single-line
                            dense
                            clearable
                            class="mr-4"
                            style="width: 1%;"
                        />
                    </template>

                    <v-tooltip top v-if="filterConditions.length > 0 || hasDatepickerCondition">
                        <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary" dark
                                    v-on="on"
                                    class="mb-2 mr-4 pa-0"
                                    min-width="48px"
                                    @click="clearFilters()"
                                >
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                        </template>
                        <span>Limpar filtros</span>
                    </v-tooltip>

                    <v-tooltip top v-if="selected.length === 1 && !editOnly && createPermission">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="duplicateSelectedItem()"
                            >
                                <v-icon>mdi-content-duplicate</v-icon>
                            </v-btn>
                        </template>
                        <span>Duplicar item selecionado</span>
                    </v-tooltip>

                    <v-tooltip top v-if="selected.length > 1 && $route.path === '/vendor-contract' && updatePermission && !sumVisibleColumns">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="editSelectedItems()"
                            >
                                <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar itens selecionados</span>
                    </v-tooltip>

                    <v-dialog v-model="dialogEditItems" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja prosseguir?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogEditItems = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="massReclassifyDialog = true, dialogEditItems = false">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-if="$route.path === '/vendor-contract' && !readOnly"
                        v-model="massReclassifyDialog" max-width="900px" 
                    >
                        <v-card style="position: relative;">
                            <v-card-title class="light--text"
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                            >
                                <span class="headline">Reclassificação em massa</span>

                                <v-spacer></v-spacer>

                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on"
                                            class="pa-0"
                                            @click="massReclassifyDialog = false"
                                            min-width="48px"
                                            text dark
                                        >
                                            <v-icon color="light">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-autocomplete
                                                label="Nova classificação SEPIN"
                                                v-model="massReclassifyAccountGroup"
                                                :items="accountGroupOptionItems"
                                                :item-text="item => item.text"
                                                return-object
                                                class="ma-0 pa-0 mt-4"
                                                hide-details
                                                clearable
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions class="px-6 py-6">
                                    <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="massReclassifyDialog = false"
                                        class="mr-4"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="massReclassify()"
                                        :loading="massReclassifying"
                                        :disabled="!massReclassifyAccountGroup"
                                    >
                                        Salvar
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top v-if="selected.length === 1 && updatePermission && !sumVisibleColumns">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="editSelectedItem()"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar item selecionado</span>
                    </v-tooltip>

                    <v-tooltip top v-if="selected.length > 0 && !editOnly && deletePermission">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="dialogMassDelete = true"
                            >
                                <v-icon>mdi-delete-sweep</v-icon>
                            </v-btn>
                        </template>
                        <span>Deletar itens selecionados</span>
                    </v-tooltip>

                    <v-dialog v-model="dialogMassDelete" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Tem certeza de que deseja excluir estes itens?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeMassDelete" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="massDeleteItemConfirm" :loading="massDeleting">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top v-if="!readOnly && !editOnly && createPermission">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="primary"
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="dialog = true"
                                :disabled="!headers || !headers.length"
                                data-testid="new-item-btn"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Novo item</span>
                    </v-tooltip>

                    <v-dialog v-model="dialog" max-width="900px" v-if="!readOnly">
                        <v-card style="position: relative;">
                            <v-card-title class="light--text"
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                            >
                                <span ref="formTitle" class="headline">{{ formTitle }}</span>

                                <v-spacer></v-spacer>

                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on"
                                            class="pa-0 mr-2"
                                            @click="returnEditedItemToDefault()"
                                            min-width="48px"
                                            text dark
                                        >
                                            <v-icon color="light">mdi-restart</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Limpar campos</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on"
                                            class="pa-0"
                                            @click="close"
                                            min-width="48px"
                                            text dark
                                            data-testid="znap-dialog-close-button"
                                        >
                                            <v-icon color="light">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <ZnapDialog
                                        :editedItem="editedItem"
                                        :editedIndex="editedIndex"
                                        :disabled="disabled"
                                        :options="options"
                                        :filterConditions="filterConditions"
                                        :crudPermission="crudPermission"
                                        :createPermission="createPermission"
                                        :updatePermission="updatePermission"
                                        :deletePermission="deletePermission"
                                    />
                                </v-container>
                            </v-card-text>
                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions class="px-6 py-6">
                                    <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="close"
                                        class="mr-4"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="save"
                                        :loading="saving"
                                        :disabled="disabled"
                                    >
                                        Salvar
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="refresh()"
                                :disabled="hasRequiredFilters()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top v-if="!readOnly">
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                :class="{ 'primary': !unsavedChanges, 'secondary': unsavedChanges }"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="massSave(getItemKey())"
                                :disabled="!unsavedChanges || sumVisibleColumns"
                                :loading="massSaving"
                            >
                                <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ unsavedChanges ? 'Salvar edições' : 'Nenhum dado foi alterado'}}
                        </span>
                    </v-tooltip>

                    <v-tooltip top v-if="!readOnly && !onlyExport && crudPermission">
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                @click="importSheet"
                                :loading="importing"
                                min-width="48px"
                                :disabled="disableImportSheet"
                            >
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                            <input
                                ref="uploader"
                                class="d-none"
                                type="file"
                                @change="onFileChanged"
                            >
                        </template>
                        <span>Importar Excel</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                @click="exportSheet"
                                :loading="exporting"
                                min-width="48px"
                                :disabled="noData"
                            >
                                <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar Excel</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="primary" dark
                                v-on="on"
                                class="mb-2 pa-0"
                                min-width="48px"
                                @click="closeTable()"
                                data-testid="znap-table-close-button"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                    <v-dialog v-model="dialogCloseTable" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Tem certeza de que deseja excluir este item?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDelete" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="deleteItemConfirm" :loading="deleting">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogEditItem" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja prosseguir?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogEditItem = false, close()" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="dialog = true, dialogEditItem = false">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>

                <v-divider class="mx-4 mb-2" v-if="showToolbar"></v-divider>

                <div v-if="tab === null">
                    <slot name="filters"></slot>
                </div>

                <div v-else>
                    <slot name="filterTabs"></slot>

                    <v-tabs-items :value="tab">
                        <v-tab-item>
                            <slot name="filters"></slot>
                        </v-tab-item>

                        <v-tab-item v-if="$route.path === '/vendor-contract' || $route.path === '/vendor-payment'">
                            <div class="d-flex align-start ma-0 mt-4 mx-5">
                                <v-btn small
                                    class="primary mr-4 mb-4"
                                    @click="newFilterConstructor()"
                                >
                                    Novo filtro
                                </v-btn>

                                <v-row no-gutters v-if="filterConstructors.length"
                                    class="ma-0 pa-0 d-flex justify-start align-center"
                                >
                                    <v-col v-for="(filterConstructor, index) in filterConstructors" :key="index"
                                        xs="12" sm="6" md="6" lg="4"
                                        class="pa-0 ma-0 mb-4 d-flex"
                                    >
                                        <v-sheet class="d-flex align-center pa-4 rounded-lg mr-4" elevation="3">
                                            <v-autocomplete
                                                label="Coluna"
                                                v-model="filterConstructor.column"
                                                :items="analyseHeaders"
                                                :item-text="item => item.text"
                                                return-object
                                                class="ma-0 pa-0 mr-2"
                                                hide-details
                                                clearable
                                            />

                                            <v-checkbox
                                                label="Não"
                                                v-model="filterConstructor.not"
                                                hide-details
                                                class="ma-0 pa-0 mr-2"
                                            ></v-checkbox>

                                            <v-select
                                                label="Operador"
                                                v-model="filterConstructor.operator"
                                                :items="filterConstructorOperators"
                                                class="ma-0 pa-0 mr-2"
                                                hide-details
                                                style="max-width: 100px;"
                                                clearable
                                            />

                                            <v-text-field
                                                label="Valor"
                                                v-model="filterConstructor.value"
                                                class="ma-0 pa-0 mr-2"
                                                hide-details
                                                :disabled="filterConstructor.operator === 'É VAZIO'"
                                            />

                                            <v-btn icon color="primary"
                                                @click="removeFilterConstructor(index)"
                                            >
                                                <v-icon color="primary">mdi-close</v-icon>
                                            </v-btn>
                                        </v-sheet>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters v-else
                                    class="ma-0 pa-0 d-flex justify-start align-center"
                                >
                                    <v-col class="pa-0 ma-0 mt-1 d-flex">
                                        <p class="ma-0">Adicione um novo filtro, escolha a coluna da tabela e um operador, selecione ou atribua um valor e atualize a página para aplicar</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>
                        
                        <v-tab-item>
                            <v-row v-if="$route.path === '/vendor-contract' || $route.path === '/vendor-payment'"
                                no-gutters class="mx-4"
                            >
                                <v-col cols="3">
                                    <v-checkbox
                                        :label="hasNotSelectedColumn(1) ? 'SELECIONAR TODOS' : 'DESMARCAR TODOS'"
                                        hide-details
                                        :input-value="!hasNotSelectedColumn(1)"
                                        @change="setHeaderHideAllColumns(1, $event)"
                                        class="ma-0 font-weight-bold"
                                    />
                                </v-col>

                                <v-col cols="3" 
                                    v-for="header in filteredHeaderSelect1" :key="header.name"
                                >
                                    <v-checkbox v-if="header.select"
                                        :label="header.reclassificationRequired ? `${header.text} *` : header.text"
                                        hide-details
                                        :input-value="!header.hideColumn"
                                        @change="setHeaderHideColumn(header, $event)"
                                        class="ma-0"
                                    />
                                </v-col>
                            </v-row>

                            <!-- <v-row v-else-if="$route.path === '/vendor-payment'"
                                no-gutters class="mx-4"
                            >
                                <v-col cols="3" 
                                    v-for="header in filteredHeaderSelect" :key="header.name"
                                >
                                    <v-checkbox v-if="header.select"
                                        :label="header.text"
                                        hide-details
                                        :input-value="!header.hideColumn"
                                        @change="setHeaderHideColumn(header, $event)"
                                        class="ma-0"
                                    />
                                </v-col>
                            </v-row> -->
                        </v-tab-item>

                        <v-tab-item>
                            <v-row no-gutters class="mx-4">
                                <v-col cols="3">
                                    <v-checkbox
                                        :label="hasNotSelectedColumn(2) ? 'SELECIONAR TODOS' : 'DESMARCAR TODOS'"
                                        hide-details
                                        :input-value="!hasNotSelectedColumn(2)"
                                        @change="setHeaderHideAllColumns(2, $event)"
                                        class="ma-0 font-weight-bold"
                                    />
                                </v-col>

                                <v-col cols="3" v-for="header in filteredHeaderSelect2" :key="header.name">
                                    <v-checkbox v-if="header.select"
                                        :label="header.reclassificationRequired ? `${header.text} *` : header.text"
                                        hide-details
                                        :input-value="!header.hideColumn"
                                        @change="setHeaderHideColumn(header, $event)"
                                        class="ma-0"
                                    />
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row no-gutters class="mx-4">
                                <v-col cols="3">
                                    <v-checkbox
                                        :label="hasNotSelectedColumn(3) ? 'SELECIONAR TODOS' : 'DESMARCAR TODOS'"
                                        hide-details
                                        :input-value="!hasNotSelectedColumn(3)"
                                        @change="setHeaderHideAllColumns(3, $event)"
                                        class="ma-0 font-weight-bold"
                                    />
                                </v-col>

                                <v-col cols="3" v-for="header in filteredHeaderSelect3" :key="header.name">
                                    <v-checkbox v-if="header.select"
                                        :label="header.reclassificationRequired ? `${header.text} *` : header.text"
                                        hide-details
                                        :input-value="!header.hideColumn"
                                        @change="setHeaderHideColumn(header, $event)"
                                        class="ma-0"
                                    />
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row no-gutters class="mx-4">
                                <v-col cols="3">
                                    <v-checkbox
                                        :label="hasNotSelectedColumn(4) ? 'SELECIONAR TODOS' : 'DESMARCAR TODOS'"
                                        hide-details
                                        :input-value="!hasNotSelectedColumn(4)"
                                        @change="setHeaderHideAllColumns(4, $event)"
                                        class="ma-0 font-weight-bold"
                                    />
                                </v-col>

                                <v-col cols="3" v-for="header in filteredHeaderSelect4" :key="header.name">
                                    <v-checkbox v-if="header.select"
                                        :label="header.reclassificationRequired ? `${header.text} *` : header.text"
                                        hide-details
                                        :input-value="!header.hideColumn"
                                        @change="setHeaderHideColumn(header, $event)"
                                        class="ma-0"
                                    />
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item>
                            <v-row no-gutters class="pa-4">
                                <v-col class="d-flex justify-start">
                                    <v-checkbox
                                        label="Somar apenas colunas visíveis"
                                        hide-details
                                        :input-value="sumVisibleColumns"
                                        @change="sumVisibleColumns = !sumVisibleColumns"
                                        class="ma-0 mr-4"
                                    />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:header.data-table-select="{ on, props }">
                <v-simple-checkbox v-if="!noData"
                    color="secondary"
                    v-bind="props"
                    v-on="on"
                    :ripple="false"
                    :disabled="sumVisibleColumns"
                ></v-simple-checkbox>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item="{ item, index, select, isSelected }">
                <tr :class="{ 'edited-row': setEditedRow(item), 'sum-row': isSumRow(index), 'selected-row': setSelectedRow(item)}"
                    class="table-row"
                >
                    <td class="d-block d-sm-table-cell" v-if="!readOnly">
                        <v-simple-checkbox v-if="!isSumRow(index)"
                            :key="item[getItemKey()]"
                            color="secondary"
                            :value="isSelected"
                            @input="select($event)"
                            :ripple="false"
                            :disabled="sumVisibleColumns"
                        ></v-simple-checkbox>
                    </td>

                    <template v-for="(header, headerIndex) in headers">
                        <td :key="headerIndex"
                            :class="{ 'actions-column text-end': header.value === 'actions'}"
                            class="d-block d-sm-table-cell"
                        >                        
                            <template v-if="header.value === 'actions' && !readOnly && !isSumRow(index)"
                                class="d-flex justify-center align-center"
                            >
                                <v-tooltip top v-if="updatePermission && !sumVisibleColumns">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon
                                            v-on="on"
                                            @click="editItem(item)"
                                        >
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar item</span>
                                </v-tooltip>

                                <v-tooltip top v-if="!editOnly && deletePermission">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon
                                            v-on="on"
                                            @click="deleteItem(item)"
                                        >
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Deletar item</span>
                                </v-tooltip>
                            </template>

                            <div v-if="header.type === 'INT' && header.columnType === 'check'"
                                style="width: 100%;" class="d-flex justify-center align-center"
                            >
                                <v-simple-checkbox
                                    color="primary"
                                    :value="formatCheckboxValue(item[header.value])"
                                    @input="setCheckboxValue($event, item, header.value)"
                                    :ripple="false"
                                    :disabled="!updatePermission || header.notEditable"
                                >
                                </v-simple-checkbox>
                            </div>

                            <!-- Open ZnapStylesDialog -->
                            <div v-if="header.value === 'css'"
                                @click="openStylesDialog(item, index)"
                                style="cursor: pointer; width: 100%; height: 100%;"
                                class="d-flex align-center"
                            >
                                <span>{{ item[header.value] }}</span>
                            </div>

                            <!-- Open ZnapFormulaDialog -->
                            <div v-if="header.value === 'formula'"
                                @click="dialogFormula = true, editedRowFormula = item"
                                style="cursor: pointer; width: 100%; height: 100%;"
                                class="d-flex align-center"
                            >
                                <span style="display: inline-block; max-width: 500px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;">
                                    {{ item[header.value] }}
                                </span>
                            </div>

                            <v-edit-dialog v-else-if="updatePermission && header.value !== sortBy[0] && !header.hide && !header.notEditable && !header.img && header.value !== 'actions' && header.columnType !== 'check' && header.value !== 'css' && header.value !== 'formula' && !isSumRow(index) && !sumVisibleColumns"
                                :return-value.sync="item[header.value]"
                                large
                                cancel-text="Cancelar"
                                save-text="Alterar"
                                @close="close"
                                @cancel="cancelSelectedOption(item, header.columnId)"
                                @open="backupSelect(index, header.columnId)"
                                @save="itemHasBeenEdited(item)"
                            >
                                <template v-if="!header.groupInfo">
                                    <span style="display: inline-block; max-width: 500px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;">
                                        {{ setEditDialogContent(header, item) }}
                                    </span>
                                </template>

                                <template v-else-if="header.groupInfo">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn small text color="primary"
                                                v-on="on"
                                            >
                                                <v-icon small color="primary" class="mr-2">mdi-eye</v-icon>
                                                Visualizar
                                            </v-btn>
                                        </template>
                                        <v-list min-width="250px" max-height="400px">
                                            <v-list-item class="white">
                                                <v-btn color="primary" small
                                                    @click="navigateTo(header, item)"
                                                >
                                                    <v-icon small class="mr-2">mdi-plus</v-icon>
                                                    Adicionar
                                                </v-btn>

                                                <v-spacer></v-spacer>

                                                <v-btn icon color="primary">
                                                    <v-icon color="primary">mdi-close</v-icon>
                                                </v-btn>
                                            </v-list-item>

                                            <v-divider class="mt-2"></v-divider>
                                            
                                            <v-list-item v-for="groupInfoItem in getGroupInfoItems(header, item)" :key="groupInfoItem.id"
                                                class="white"
                                            >
                                                <v-list-item-title>{{ groupInfoItem.text }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>

                                <template v-slot:input>
                                    <v-text-field v-if="!header.options && (header.type === 'VARCHAR' || header.type === 'TEXT')"
                                        v-model="item[header.value]"
                                        :label="header.text"
                                        type="text"
                                        single-line
                                        :counter="header.length"
                                        :maxlength="header.length"
                                    />

                                    <v-text-field v-if="!header.options && header.type === 'INT' && header.columnType !== 'check'"
                                        v-model.number="item[header.value]"
                                        :label="header.text"
                                        type="number"
                                        single-line
                                        :counter="header.length"
                                        :maxlength="header.length"
                                    />

                                    <v-text-field v-if="!header.options && header.type === 'DECIMAL'"
                                        v-model.number="item[header.value]"
                                        @change="formatFloat(item[header.value])"
                                        :label="header.text"
                                        type="number"
                                        single-line
                                        :counter="header.length"
                                        :maxlength="header.length"
                                    />

                                    <v-autocomplete v-if="header.options && header.columnType !== 'radio'"
                                        :label="header.text"
                                        v-model="item[header.value]"
                                        :items="getOptions(header)"
                                        item-value="text"
                                        return-object
                                        :clearable="!isRequired(header)"
                                        @change="setSelectedOption(item, header.columnId, header.value, $event)"
                                    />

                                    <v-select v-if="header.options && header.columnType === 'radio'"
                                        :label="header.text"
                                        :items="header.options"
                                        item-value="text"
                                        v-model="item[header.value]"
                                        :clearable="!isRequired(header)"
                                    />

                                    <v-menu v-if="header.type === 'DATE' || header.type === 'DATETIME'"
                                        :ref="'menu' + index + header.value"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                :value="formatDate(item[header.value])"
                                                :label="header.text"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                                clearable
                                                @click:clear="setSelectedOption(item, header.columnId, header.value, $event)"
                                            ></v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="item[header.value]"
                                            no-title
                                            scrollable
                                            color="primary"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="saveSelectedDate(item[header.value], index, header.value)">
                                                Confirmar
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </v-edit-dialog>

                            <div v-else-if="header.img" class="d-flex justify-center">
                                <v-avatar v-if="item[header.value]" class="my-1" size="54">
                                    <v-img :src="item[header.value]">
                                        <template v-slot:placeholder>
                                            <v-skeleton-loader
                                                type="avatar"
                                            ></v-skeleton-loader>
                                        </template>
                                    </v-img>
                                </v-avatar>

                                <v-avatar v-else class="my-1" size="54">
                                    <v-sheet>
                                        <v-icon color="primary" size="62">mdi-account-circle</v-icon>
                                    </v-sheet>
                                </v-avatar>
                            </div>

                            <div v-else-if="header.columnType !== 'check' && header.align === 'center' && header.value !== 'css' && header.value !== 'formula'" class="d-flex justify-center">
                                <span>{{ setEditDialogContent(header, item) }}</span>
                            </div>

                            <div v-else-if="header.columnType !== 'check' && header.value !== 'css' && header.value !== 'formula'"
                                class="d-flex" :class="header.align === 'center' ? ' justify-center': 'justify-start'"
                                style="display: inline-block; max-width: 500px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                            >
                                <span>{{ setEditDialogContent(header, item) }}</span>
                            </div>
                        </td>
                    </template> 
                </tr>
            </template>

            <template v-slot:no-data>
                <div class="d-flex justify-center"
                    style="max-width: 97vw !important"
                >
                    <span data-testid="znap-table-no-data-message"
                        class="text-h6 primary--text"
                    >
                        {{ noDataMessage }}
                    </span>
                </div>
            </template>

            <template v-if="serverSidePagination" v-slot:footer>
                <v-footer height="59px" class="px-2 py-0 rounded-b d-flex justify-end align-center"
                    style="background-color: white; border-color: black; border-top: thin solid rgba(0, 0, 0, 0.12); font-size: 0.75rem;"
                >
                    <div v-if="$route.path === '/vendor-contract'">
                        <span class="text-body-2 mr-4 font-weight-bold">{{ `Valor contratado: ${formatFloat(erpValorContratadoSumValue)}`}}</span>
                        <span class="text-body-2 mr-4 font-weight-bold">{{ `Valor da hora contratada: ${formatFloat(erpValorHoraSumValue)}`}}</span>
                        <span class="text-body-2 mr-4 font-weight-bold">{{ `Valor da taxa ERP: ${formatFloat(erpItemRateAmountSumValue)}`}}</span>
                        <span class="text-body-2 font-weight-bold">{{ `Valor do item da taxa ERP: ${formatFloat(erpItemRateSumValue)}`}}</span>
                    </div>

                    <div v-if="$route.path === '/vendor-payment'">
                        <span class="mr-4 text-body-2 font-weight-bold">{{ `Valor a débito: ${formatFloat(sumDebitValue)}`}}</span>
                        <span class="text-body-2 mr-4 font-weight-bold">{{ `Valor a crédito: ${formatFloat(sumCreditValue)}`}}</span>
                        <span class="text-body-2 mr-4 font-weight-bold">{{ `Valor do pagamento: ${formatFloat(paymentValue)}`}}</span>
                    </div>

                    <v-spacer></v-spacer>

                    <span>Linhas por página:</span>
                    <v-text-field class="ma-0 pa-0 mx-8"
                        type="number"
                        style="max-width: 42px; font-size: 0.75rem;"
                        hide-details
                        v-model.number="serverSideItemsPerPage"
                    ></v-text-field>

                    <span class="mr-4">{{ serverSidePaginationFirstItem }} - {{ serverSidePaginationLastItem }} de {{ serverSideItemsLength }}</span>

                    <v-btn icon class="mr-4"
                        :disabled="serverSideCurrentPage === 0"
                        @click="serverSidePreviousPage()"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon @click="serverSideNextPage()"
                        :disabled="!headers || !headers.length || serverSidePaginationLastItem >= serverSideItemsLength"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-footer>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogStyles" max-width="1000px" v-if="editedRowStyles">
            <v-card style="position: relative;">
                <v-card-title
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                    style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                >
                    <span class="light--text">{{ `Personalizar estilo da linha - ${editedRowStyles.description}`}}</span>
                </v-card-title>
                <v-card-text>
                    <ZnapStylesDialog
                        :editedRowStyles="editedRowStyles.css"
                        @closeDialogStyles="dialogStyles = false, editedRowStyles = null"
                        @setEditedRowStyles="setEditedRowStyles($event)"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogFormula" max-width="1000px" v-if="editedRowFormula">
            <ZnapFormulaDialog
                :editedRowFormula="editedRowFormula"
                :filterConditions="filterConditions"
                @closeDialogFormula="dialogFormula = false, editedRowFormula = null"
                @setEditedRowFormula="setEditedRowFormula($event)"
                :crudPermission="crudPermission"
                :createPermission="createPermission"
                :updatePermission="updatePermission"
                :deletePermission="deletePermission"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import configs from '@/configs.js'
import { mapGetters } from 'vuex'
import ZnapDialog from '@/components/znap/ZnapDialog'
import ZnapStylesDialog from '@/components/znap/ZnapStylesDialog'
import ZnapFormulaDialog from '@/components/znap/ZnapFormulaDialog'
import lodash from 'lodash'
import moment from 'moment'
import getModulePort from '@/helpers/getModulePort'

export default {
    name: 'ZnapTable',

    components: { ZnapDialog, ZnapStylesDialog, ZnapFormulaDialog },

    props: {
        endpoint: { type: Array, required: true },
        // tableName: { type: String, required: true },
        // tableIcon: { type: String, required: true, default: 'mdi-view-list' },
        tableDescription: { type: String, required: false },
        tableRows: { type: Number, required: true, default: 1000 },
        options: { type: Array, required: true },
        noData: { type: Boolean },
        noDataMessage: { type: String },
        filterConditions: { type: Array },
        checkboxFilters: { type: Array },
        loadTable: { type: Boolean },
        defaultFieldsComplement: { type: Array, default: () => [] },
        // payloadComplement: { type: Object },
        readOnly: { type: Boolean, required: false, default: false },
        editOnly: { type: Boolean, required: false, default: false },
        serverSidePagination: { type: Boolean, required: false, default: false },
        serverSideProps: { type: Object, required: false, default: () => {} },
        showToolbar: { type: Boolean, required: false, default: true },
        clearTableSelected: { type: Boolean, required: false, default: false },
        searchProp: { type: String, required: false, default: null },
        tab: { type: Number, required: false, default: null },
        tabItems: { type: Array, required: false, default: () => [] },
        onlyExport: { type: Boolean, required: false, default: false },
        setPopulateNotRequiredOptions: { type: Boolean, required: false, default: false }
    },

    data() {
        return {
            analyseHeaders: [],
            backupHeaders: [],
            headers: [],
            apiResult: {
                items: [],
                headers: [],
                columns: [],
            },

            currentItems: null,

            footerProps: {
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 500, 1000, 5000, -1],
                itemsPerPageAllText: 'TODOS'
            },

            sortBy: [],
            sortDesc: null,

            serverSideItemsLength: 0,
            serverSideItemsPerPage: 100,
            serverSideCurrentItemsPerPage: null,
            serverSideCurrentPage: 0,
            serverSidePaginationSearch: '',
            serverSideFilteredItems: [],
            
            sumVisibleColumns: false,
            valueSum: 0,
            erpItemRateAmountSum: 0,
            erpItemRateSum: 0,
            erpValorContratadoSum: 0,
            erpValorHoraSum: 0,
            debitValueSum: 0,
            creditValueSum: 0,
            paymentValueSum: 0,

            notShowReclassifications: true,
            showReclassificationLayout: false,

            showDatepicker: false,

            dialog: false,
            dialogDelete: false,
            dialogEditItem: false,
            dialogMassDelete: false,
            dialogCloseTable: false,

            dialogStyles: false,
            editedRowStyles: null,

            dialogFormula: false,
            editedRowFormula: null,

            backupCurrentDateValues: null,
            datepicker: [],
            editedIndex: -1,
            search: '',
            awaitingSearch: false,
            selected: [],
            saving: false,
            deleting: false,
            massDeleting: false,
            importing: false,
            exporting: false,
            backupSelectValue: null,
            windowSize: { x: 0, y: 0 },
            znapFiltersContainerHeight: 0,

            pagination: {},
            backupPage: 1,
            backupFilterConditionsLength: null,
    
            loading: false,
            massSaving: false,
    
            // Dialogs
            editedItem: [],
            editedItemsId: [],
            defaultItem: [],
    
            // Unsaved changes
            unsavedChanges: false,
            itemsInitialState: [],
    
            checkbox: false,

            filterConstructor: {
                not: false,
                column: null,
                operator: null,
                value: null
            },

            filterConstructors: [],
            filterConstructorColumns: [],
            filterConstructorOperators: ['=', '<>', '>', '>=', '<', '<=', 'É VAZIO'],

            massReclassifyDialog: false,
            massReclassifyAccountGroup: null,
            massReclassifying: false,
            dialogEditItems: false,

            hasPopulatedNotRequiredOptions: false,

            crudPermission: true,
            createPermission: true,
            updatePermission: true,
            deletePermission: true,
        }
    },

    computed: {
        ...mapGetters('auth', ['getHash', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        hasOptions() {
            let height = 0

            if (this.znapFiltersContainerHeight) {
                height = this.znapFiltersContainerHeight
            }

            if (this.tabItems.length) {
                height += 28
            }

            return height
        },

        formTitle() {
            return this.editedIndex <= 0 ? 'Novo item' : 'Editar item'
        },

        disabled() {
            let validation = []

            this.editedItem.forEach((item) => {
                if (item.rules.includes('required') && !item.hide) {
                    if (item.value === null || item.value === '') {
                        validation.push('disabled')
                    }
                }
            })

            return validation.includes('disabled') ? true : false
        },

        hasDatepickerCondition() {
            let datepickerConditions = this.options.filter(o => o.is === 'Datepicker' && o.value)
            return datepickerConditions.length ? true : false
        },

        disableImportSheet() {
            let disabled = false

            if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') {
                disabled = false
            } else {
                disabled = !this.headers || !this.headers.length
            }

            return disabled
        },

        filteredHeaderSelect() {
            if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') {
                return this.analyseHeaders.length ? this.analyseHeaders.filter(h => h.select) : []
            }

            // if (this.$route.path === '/vendor-payment') {
            //     return this.apiResult.headers.filter(h => h.select)
            // }

            return []
        },

        filteredHeaderSelect1() {
            return this.filteredHeaderSelect.filter((h, index) => index >= 0 && index < 15)
        },

        filteredHeaderSelect2() {
            return this.filteredHeaderSelect.filter((h, index) => index >= 15 && index < 30)
        },

        filteredHeaderSelect3() {
            return this.filteredHeaderSelect.filter((h, index) => index >= 30 && index < 45)
        },

        filteredHeaderSelect4() {
            return this.filteredHeaderSelect.filter((h, index) => index >= 45 && index < 60)
        },

        filteredHeaderSelect5() {
            return this.filteredHeaderSelect.filter((h, index) => index >= 60)
        },

        itemsPerPage() {
            if (this.serverSidePagination) {
                return this.serverSideCurrentItemsPerPage
            } else {
                if (this.search) {
                    return 1000
                } else {
                    return 50
                }
            }
        },

        serverSidePaginationFirstItem() {
            return this.serverSideCurrentPage * this.serverSideCurrentItemsPerPage + 1
        },

        serverSidePaginationLastItem() {
            let lastItem = this.serverSideCurrentPage * this.serverSideCurrentItemsPerPage + this.serverSideCurrentItemsPerPage
            if (this.serverSideItemsLength <= lastItem) {
                return this.serverSideItemsLength
            }

            return lastItem
        },

        erpItemRateAmountSumValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.erp_item_rateamount || 0)
                }, 0)
            } else {
                return this.erpItemRateAmountSum
            }
        },

        erpItemRateSumValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.erp_item_rate || 0)
                }, 0)
            } else {
                return this.erpItemRateSum
            }
        },

        erpValorContratadoSumValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.erp_valor_contratado || 0)
                }, 0)
            } else {
                return this.erpValorContratadoSum
            }
        },

        erpValorHoraSumValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.erp_valor_hora || 0)
                }, 0)
            } else {
                return this.erpValorHoraSum
            }
        },

        sumDebitValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.debit_value || 0)
                }, 0)
            } else {
                return this.debitValueSum
            }
        },

        sumCreditValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.credit_value || 0)
                }, 0)
            } else {
                return this.creditValueSum
            }
        },

        paymentValue() {
            if (this.sumVisibleColumns) {
                return this.currentItems.reduce((acc, item) => {
                    return acc + parseFloat(item.payment_value || 0)
                }, 0)
            } else {
                return this.paymentValueSum
            }
        },

        accountGroupOptionItems() {
            let option = this.options.find(i => i.column === 'id_account_group')
            if (option) {
                return option.items
            } else {
                return []
            }
        }
    },

    watch: {
        dialog (val) {
            val || this.close()
        },

        dialogDelete (val) {
            val || this.closeDelete()
        },

        dialogMassDelete (val) {
            val || this.closeMassDelete()
        },

        loadTable (val) {
            if (val) {
                this.load()
            }
        },

        selected(val) {
            this.$emit('setSelectedItem', val)
        },

        clearTableSelected(val) {
            if (val) {
                this.selected = []
                this.$emit('setClearTableSelected', false)
            }
        },

        setPopulateNotRequiredOptions(val) {
            if (val) {
                this.populateNotRequiredOptions()
                this.hasPopulatedNotRequiredOptions = true
                this.$emit('setBackPopulateNotRequiredOptions')
            }
        },
        
        serverSidePaginationSearch(val) {
            if (val) {
                this.serverSideFilteredItems = this.apiResult.items.filter(item => {
                    let found = false

                    for (const key in item) {
                        if (item[key] && item[key].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== -1) {
                            found = true
                        }
                    }

                    return found
                })
            } else {
                this.serverSideFilteredItems = lodash.cloneDeep(this.apiResult.items)
            }

            if (!this.serverSideFilteredItems.length) {
                this.$emit('setNoData', true)
                this.$emit('setNoDataMessage', 'Não há dados disponíveis')
            }
        },

        sortDesc(val) {
            let option = typeof val === 'object' ? val[0] : val
            if (this.serverSidePagination && this.sortBy.length > 0) {
                if (this.serverSidePaginationSearch) {
                    if (option) {
                        this.serverSideFilteredItems.sort((a,b) => {
                            return a[this.sortBy[0]] > b[this.sortBy[0]] ? -1 : a[this.sortBy[0]] < b[this.sortBy[0]] ? 1 : 0;
                        })
                    } else {
                        this.serverSideFilteredItems.sort((a,b) => {
                            return a[this.sortBy[0]] < b[this.sortBy[0]] ? -1 : a[this.sortBy[0]] > b[this.sortBy[0]] ? 1 : 0;
                        })
                    }
                } else {
                    if (option) {
                        this.apiResult.items.sort((a,b) => {
                            return a[this.sortBy[0]] > b[this.sortBy[0]] ? -1 : a[this.sortBy[0]] < b[this.sortBy[0]] ? 1 : 0;
                        })
                    } else {
                        this.apiResult.items.sort((a,b) => {
                            return a[this.sortBy[0]] < b[this.sortBy[0]] ? -1 : a[val[0]] > b[this.sortBy[0]] ? 1 : 0;
                        })
                    }
                }
            }
        }
    },

    async created() {
        let routePath = this.$route.path

        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }

        if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') {
            this.analyseHeaders = await this.getAnalyseHeaders()
            if (this.analyseHeaders.length) {
                this.filterConstructorColumns = lodash.cloneDeep(this.analyseHeaders)
            } 
        }

        this.load()
    },

    methods: {
        async getAnalyseHeaders() {
            try {
                const endpoint = this.$route.path.replace('/', '')
                const res = await this.$http.post(`${this.$ipVendor}${endpoint}/analyse-headers`, {})
                if (res) {
                    return res.data.headers
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        setHeaderHideAllColumns(tab, e) {
            this[`filteredHeaderSelect${tab}`].forEach(i => {
                let index = this.analyseHeaders.findIndex(h => h === i)
                this.analyseHeaders[index].visible = e
                this.analyseHeaders[index].hideColumn = !e
            })
        },

        hasNotSelectedColumn(tab) {
            return !!this[`filteredHeaderSelect${tab}`].filter(i => !i.visible).length
        },

        customFilter (value, search, item) {
            return value != null &&
            search != null &&
            value.toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1 ||
            ((this.$route.path === '/vendor-payment' || this.$route.path === '/vendor-contract') && !item[this.getItemKey()])
        },

        getFiltered(e) {
            this.currentItems = e

            if (this.$route.path === '/vendor-payment') {
                for (const key in this.apiResult.items[0]) {
                    let foundHeader = this.apiResult.headers.find(h => h.value === key)
    
                    if (foundHeader?.sum) {
                        this.apiResult.items[0][key] = 0
                        this.apiResult.items[0][key] = this.sumColumn(foundHeader)
                    }
                }
            }
        },

        sumColumn(header) {
            let items
            if (this.search) {
                items = this.currentItems
            } else {
                items = this.apiResult.items
            }

            return items.reduce((acc, item) => {
                return acc + parseFloat(item[header.value] || 0)
            }, 0)
        },

        isSumRow(index) {
            // return (this.$route.path === '/vendor-payment' && index === 0)
            return (false && index === 0)
        },

        setHeaderHideColumn(header, e) {
            if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') {
                let index = this.analyseHeaders.findIndex(h => h === header)
                this.analyseHeaders[index].visible = e
                this.analyseHeaders[index].hideColumn = !e
            }

            // if (this.$route.path === '/vendor-payment') {
            //     let index = this.backupHeaders.findIndex(h => h.value === header.value)
            //     this.backupHeaders[index].hideColumn = !e
    
            //     if (header.options) {
            //         let idIndex = this.backupHeaders.findIndex(h => h.value === header.columnId)
            //         this.backupHeaders[idIndex]['hideColumn'] = !e
            //     }
            // }
        },

        setChartAccountDescriptionValue(text) {
            let title = text?.split(' || ')[0] || null
            let description = text?.split(' || ')[1] || null
            return title + '\r\n' + '\r\n' + description
        },

        serverSideNextPage() {
            this.serverSideCurrentPage++
            this.load()
        },

        serverSidePreviousPage() {
            this.serverSideCurrentPage--
            this.load()
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        navigateTo(header, item) {
            let groupInfoPath = this.apiResult.groupInfo[header.groupInfo].path
            let route = groupInfoPath.route
            let systemModule = groupInfoPath.module.toLowerCase()

            let url = window.location.href
            let path = null
            let port = getModulePort(systemModule)

            if (url.includes(':80')) {
                path = `http://${configs.system}.${systemModule}.znaptech:${port}/?token=${this.getHash}&route=${route}&id_group=${item.id_group}`
            } else {
                path = `https://${configs.system}${configs.env === 'homol' ? '.homol' : ''}.${systemModule}.znaptech.com/?token=${this.getHash}&route=${route}&id_group=${item.id_group}`
            }

            return window.open(path)
        },

        setEditDialogContent(header, item) {
            if (header.type === 'DATE' || header.type === 'DATETIME') {
                return this.formatDate(item[header.value])
            } else if (header.type === 'DECIMAL') {
                return this.formatFloat(item[header.value])
            } else if (header.value === 'cpf' || header.value === 'cnpj') {
                return this.formatCpfCnpj(item[header.value])
            } else {
                return item[header.value] || ''
            }
        },

        isRequired(header) {
            if (header.rules) {
                return header.rules.includes('required')
            } else {
                return false
            }
        },

        openStylesDialog(item, index) {
            this.dialogStyles = true
            this.editedRowStyles = {
                id: item.id_account_group,
                css: item.css,
                row: index 
            }
        },

        setEditedRowStyle(event) {
            let index = this.editedRowStyles.row
            let id = this.editedRowStyles.id

            this.apiResult.items[index].css = event
            this.editedItemsId.push(id)
            this.unsavedChanges = true
            
            this.editedRowStyles = null
            this.dialogStyles = false
        },

        async saveEditedRowStyle(payload) {
            try {
                const res = await this.$http.put(`${this.$ipAccount}account-group/edit-css`, { ...payload })
                if (res) {
                    this.setEditedRowStyle(payload.items[0].css)
                    this.dialogStyles = false
                    this.saving = false
                }
            } catch (err) {
                this.$fnError(err)
                this.saving = false
            }

            this.dialogStyles = false
        },

        setEditedRowFormula(event) {
            let index = this.apiResult.items.findIndex(item => item.id_account_group_calc === this.editedRowFormula.id_account_group_calc)
            let idAccountGroupCalc = this.apiResult.items.find(item => item.id_account_group_calc === this.editedRowFormula.id_account_group_calc).id_account_group_calc
            
            if (this.apiResult.items[index].formula !== event) {
                this.apiResult.items[index].formula = event.formula
                this.apiResult.items[index].variable = event.variable
                this.editedItemsId.push(idAccountGroupCalc)
                this.unsavedChanges = true
            }
            
            this.editedRowFormula = null
            this.dialogFormula = false
        },

        closeDatepicker(index) {
            this.datepicker[index] = false
            this.datepicker = []
        },

        clearFilters() {
            let requiredOption = this.options.find(option => option.required)
            if (requiredOption) this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + requiredOption.label + ' para prosseguir')
            if (requiredOption) this.selected = []
            if (this.serverSidePagination) this.clearServerSidePaginationOptions()
            if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') {
                this.valueSum = 0
                this.erpItemRateAmountSum = 0
                this.erpItemRateSum = 0
                this.erpValorContratadoSum = 0
                this.erpValorHoraSum = 0
                this.debitValueSum = 0
                this.creditValueSum = 0
                this.paymentValueSum = 0
            }
            this.$emit('clearFilters', [])
        },

        clearServerSidePaginationOptions() {
            this.serverSideCurrentPage = 0
            this.serverSideItemsPerPage = 100
            this.serverSideCurrentItemsPerPage = 100
        },

        refresh() {
            this.backupPage = this.pagination.page

            if (this.serverSidePagination) {
                if (this.filterConditions.length !== this.backupFilterConditionsLength) {
                    this.serverSideCurrentPage = 0
                }

                this.backupFilterConditionsLength = this.filterConditions.length
            }

            this.load()
        },

        updatePage (pagination) {
            if (pagination.page !== this.pagination.page) {
                pagination.page = this.pagination.page
            }
            this.pagination = pagination
        },
        
        load() {
            this.$emit('setLoadTable', false)

            let hasRequiredFilters = this.hasRequiredFilters()
            let requiredFilterIsEmpty = this.requiredFilterIsEmpty()
            let requiredFilter = this.options.find(option => option.required)

            if (hasRequiredFilters && requiredFilterIsEmpty) {
                this.populateRequiredOptions()
                this.$emit('setNoData', true)
                this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + requiredFilter.label + ' para prosseguir')
            } else if (hasRequiredFilters && !requiredFilterIsEmpty) {
                this.clearTableData()
                this.$emit('setNoData', true)
                this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + requiredFilter.label + ' para prosseguir')
                this.$emit('clearNotRequiredOptionsItems')
            } else {
                this.loading = true
                this.clearTableData()
                if (!this.hasPopulatedNotRequiredOptions) this.populateNotRequiredOptions()
                
                let foundOption = this.options.find(option => option.payloadComplement) || {}
                let payload = this.setPayload(foundOption.payloadComplement, this.endpoint[2])

                let endpoint = '/list'
                if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') endpoint = '/analyse'

                let checkDate = payload.filter.conditions.findIndex(condition => 'not' in condition)
                if (checkDate > -1) {
                    let formattedDate = moment(payload.filter.conditions[checkDate].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                    formattedDate = formattedDate === 'Data inválida' ?
                        moment(payload.filter.conditions[checkDate].value).format('YYYY-MM-DD') :
                        formattedDate
                    payload.filter.conditions[checkDate].value = formattedDate === 'Data inválida' ?
                        payload.filter.conditions[checkDate].value :
                        formattedDate
                }

                this.$http.post(this.endpoint[0] + this.endpoint[1] + endpoint, payload)
                .then(res => {
                    this.apiResult = {
                        columns: res.data.columns,
                        headers: res.data.headers,
                        items: res.data.rows,
                        groupInfo: res.data.groupInfo || null,
                    }

                    this.serverSideItemsLength = res.data.totalRows
                    this.erpItemRateAmountSum = res.data.erpItemRateAmountSum
                    this.erpItemRateSum = res.data.erpItemRateSum
                    this.erpValorContratadoSum = res.data.erpValorContratadoSum
                    this.erpValorHoraSum = res.data.erpValorHoraSum
                    this.creditValueSum = res.data.creditValueSum
                    this.debitValueSum = res.data.debitValueSum
                    this.paymentValueSum = res.data.paymentValueSum


                    this.apiResult.items = this.setItems(res.data.rows, res.data.columns)

                    if (this.tab !== null) this.backupHeaders = lodash.cloneDeep(res.data.headers)
                    let _headers = this.setHeaders(res.data.headers, res.data.columns)
    
                    // EditedItem is used to create table dialogs
                    let defaultFields = this.setDefaultFields(_headers)
                    this.defaultItem = lodash.cloneDeep(defaultFields)
                    this.editedItem = defaultFields
    
                    // Actions
                    _headers.push({ text: '', value: 'actions', sortable: false })
                    this.headers = _headers
    
                    // Unsaved changes
                    this.itemsInitialState = lodash.cloneDeep(this.apiResult.items)

                    if (res.data.rows.length === 0) {
                        this.$emit('setNoDataMessage', 'Não há dados disponíveis')
                    } else {
                        this.$emit('setNoData', false)
                    }

                    this.pagination.page = this.backupPage

                    if (this.serverSidePaginationSearch) {
                        this.serverSideFilteredItems = this.apiResult.items.filter(item => {
                            let found = false
    
                            for (const key in item) {
                                if (item[key] && item[key].toString().toLowerCase().indexOf(this.serverSidePaginationSearch.toString().toLowerCase()) !== -1) {
                                    found = true
                                }
                            }
    
                            return found
                        })
                    }

                    return this.loading = false
                })
                .catch((err)=>{
                    this.$fnError(err)
                })
            }
        },

        populateRequiredOptions() {
            this.options.forEach((option, i) => {
                if (option.required && option.is !== 'Datepicker') {
                    option.items = []

                    let payload = {
                        filter: {
                            conditions: []
                        }
                    }

                    if (option.filter) {
                        payload.filter = {
                            ...option.filter
                        }
                    } else if (option.conditions) {
                        payload.filter.conditions = option.conditions
                    }

                    let pathMethod = '/list-options'
                    if (option.endpoint[2]) pathMethod = option.endpoint[2]

                    this.$http.post(option.endpoint[0] + option.endpoint[1] + pathMethod, { ...payload } )
                        .then((res) => {
                            res.data.rows.forEach(row => {
                                return this.options[i].items.push({ id: row.id, text: row.text })
                            })
                        }).catch((err) => {
                            this.$fnError(err)
                        })
                }    
            })
        },

        populateNotRequiredOptions() {
            this.options.forEach((option, i) => {
                if (option.is === "ComboBox") {
                    let populate = true

                    this.filterConditions.forEach(condition => {
                        if (condition.column === option.column) {
                            populate = false
                        }
                    })

                    let payload = {
                        filter: {
                            conditions: []
                        }
                    }

                    if (this.$route.path === '/vendor-contract' && (option.column === 'id_consolidation_key' || option.column === 'transaction_number')) {
                        let postingDateOption = this.options.find(o => o.column === 'posting_date')
                        let index = option.conditions.findIndex(c => c.column === 'posting_date')
                        option.conditions[index].value = postingDateOption.value.value
                        option.conditions[index].value2 = postingDateOption.value.value2
                    }

                    if (this.$route.path === '/vendor-contract' && option.column === 'transaction_number') {
                        payload['mainColumn'] = 'transaction_number'
                    }

                    if (option.filterable) {
                        this.filterConditions.forEach(condition => {
                            if (condition.required) {
                                payload.filter.conditions.push(condition)
                            }
                        })
                    }

                    if (!option.required && populate) {
                        option.items = []

                        if (option.filter) {
                            payload.filter = {
                                ...option.filter
                            }
                        } else if (option.conditions) {
                            payload.filter.conditions.push(...option.conditions)
                        }

                        let pathMethod = '/list-options'
                        if (option.endpoint[2]) pathMethod = option.endpoint[2]

                        this.$http.post(option.endpoint[0] + option.endpoint[1] + pathMethod, { ...payload } )
                            .then((res) => {
                                return this.options[i].items = res.data.rows
                            }).catch((err) => {
                                this.$fnError(err)
                            })
                    }
                }
            })
        },

        hasRequiredFilters() {
            let requiredFilters = null
            let hasRequiredFilters = false

            this.options.forEach(option => {
                if (option.required) requiredFilters = true
            })

            if (requiredFilters && this.filterConditions.length === 0) {
                hasRequiredFilters = true
            }

            let datepickerConditions = this.options.filter(o => o.is === 'Datepicker' && o.value)
            if (datepickerConditions) {
                if (datepickerConditions.length) hasRequiredFilters = false
            }

            return hasRequiredFilters
        },

        requiredFilterIsEmpty() {
            let requiredFilterIsEmpty = false

            this.options.forEach(option => {
                if (option.is !== 'Datepicker' && option.required && !option.items.length) {
                    requiredFilterIsEmpty = true
                }
            })

            return requiredFilterIsEmpty
        },

        setPayload(payloadComplement = {}, endpointFilter = {}) {
            let conditions = []

            if (endpointFilter.conditions) {
                conditions = [ ...this.filterConditions, ...endpointFilter.conditions ]
            } else {
                conditions = [ ...this.filterConditions ]
            }

            let checkboxConditions = {}
            let checkboxInConditions = []
            if (this.checkboxFilters) {
                this.checkboxFilters.forEach(f => {
                    let option = this.options.find(o => o.is === "Checkbox" && o.column === f.column)
                    
                    if (option.inConditions && f.value) {
                        checkboxInConditions.push({
                            AndOr: 'AND',
                            column: 'tag',
                            not: true,
                            operator: 'CONTAINS',
                            value: '_REPORT'
                        })
                    } else {
                        checkboxConditions[f.column] = f.value
                    }
                })
            }

            let datepickerConditions = []
            let datepickerFilters = this.options.filter(o => o.is === 'Datepicker' && o.value)
            if (datepickerFilters.length) {

                if (this.backupCurrentDateValues) {
                    let differentValue = this.backupCurrentDateValues.value !== datepickerFilters[0].value.value
                    let differentValue2 = this.backupCurrentDateValues.value2 !== datepickerFilters[0].value.value2
                    
                    if (differentValue || differentValue2) {
                        this.clearServerSidePaginationOptions()
                    }
                }

                datepickerFilters.forEach((f, index) => {
                    if (index === 0) this.backupCurrentDateValues = f.value
                    let value2 = f.value.value2 ? this.setDatepickerValue2(f.value.value2) : null
                    datepickerConditions.push({
                        AndOr: "AND",
                        column: f.column,
                        operator: "BETWEEN",
                        value: f.value.value || null,
                        value2
                    })
                })
            }

            let filterConstructors = []
            let serverSideConditions = []
            if (this.serverSidePagination) {
                this.serverSideCurrentItemsPerPage = this.serverSideItemsPerPage

                if (this.notShowReclassifications && this.$route.path !== '/vendor-payment' && this.$route.path !== '/vendor-contract') {
                    conditions.push({
                        AndOr: 'AND',
                        column: 'id_status',
                        operator: 'IS',
                        value: null
                    })
                }

                if (this.showReclassificationLayout) {
                    this.analyseHeaders.forEach(header => {
                        if (header.reclassificationRequired) {
                            header.visible = true
                            header.hideColumn = false
                        }
                    })
                }

                if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') {
                    if (this.filterConstructors.length) {
                        let validFilters = this.filterConstructors.filter(i => i.column && i.operator)
                        filterConstructors = validFilters.map(i => {
                            if (i.column && i.operator) {
                                return {
                                    AndOr: 'AND',
                                    column: i.column.name,
                                    operator: i.operator === 'É VAZIO' ? 'IS' : i.operator,
                                    value: i.operator === 'É VAZIO' ? null : i.value,
                                    not: i.not,
                                }
                            }
                        })
                    }
                }
            }

            return {
                headers: this.backupHeaders.length ? this.backupHeaders : null,
                columns: this.analyseHeaders.length ? this.analyseHeaders : null,
                queryType: this.sumVisibleColumns ? 'total' : null,
                ...payloadComplement,
                ...checkboxConditions,
                filter: {
                    tableRows: this.serverSidePagination ? this.serverSideItemsPerPage : this.tableRows,
                    page: this.serverSidePagination ? this.serverSideCurrentPage : null,
                    conditions: [
                        ...conditions,
                        ...datepickerConditions,
                        ...checkboxInConditions,
                        ...serverSideConditions,
                        ...filterConstructors,
                    ]
                }
            }
        },

        setDatepickerValue2(value2) {
            let fixed = value2.replace('00:00:00', '23:59:59')
            return fixed
        },

        setItems(items, columns) {
            columns.forEach(column => {
                if (column.columnType === 'DATE' || column.columnType === 'DATETIME') {
                    items.forEach(item => {
                        if (item[column.columnAlias]) {
                            item[column.columnAlias] = moment.utc(item[column.columnAlias]).format("YYYY-MM-DD")
                        }
                    })
                }
            })
            
            return items
        },

        setHeaders(headers, columns) {
            let _headers = []

            headers.forEach((header, i) => {
                let width = ''
                let type = ''
                let rules = []
                let hide = false
                let key = ''
                let length = null
                let index = columns.map(column => column.columnAlias).indexOf(header.value)

                if (index === -1) {
                    index = i
                }

                if (header.width !== undefined ) {
                    width = header.width
                } else {
                    width = "1%"
                }

                if (header.type !== undefined) {
                    type = header.type
                } else if (header.groupInfo) {
                    type = 'groupInfo'
                } else {
                    if (header.file) {
                        type = 'IMG'
                    } else {
                        if (columns[index]) {
                            type = columns[index].columnType
                        }
                    }
                }

                if (header.rules !== undefined) {
                    rules.push(header.rules)
                } else {
                    let columnIdIndex = index

                    if (header.columnId) {
                        columnIdIndex = columns.map(column => column.columnAlias).indexOf(header.columnId)
                    }

                    if (columns[columnIdIndex]) {
                        if (columns[columnIdIndex].nullColumn === 'NO') {
                            rules.push('required')
                        }
                    }
                }

                // if header hide is true table column will show but will not appear at edit dialog
                if (header.hide !== undefined) {
                    hide = header.hide
                } else {
                    if (columns[index]) {
                        if (columns[index].key === 'MUL' || columns[index].key === 'PRI') {
                            hide = true
                            key = columns[index].key
                        }
                    }
                }

                if (header.length !== undefined) {
                    length = header.length
                } else {
                    if (columns[index]) {
                        length = columns[index].columnLength
                    }
                }
                
                if (!header.hideColumn) {
                    _headers.push({ ...header, width, type, rules, hide, key, length })
                }
            })

            return _headers
        },

        setDefaultFields(_headers) {
            let defaultFields = []

            _headers.forEach(header => {
                defaultFields.push({
                    name: header.value,
                    label: header.text,
                    value: header.columnType === 'check' ? 0 : null,
                    type: header.type,
                    rules: header.rules,
                    hide: header.hide,
                    key: header.key,
                    options: header.options,
                    columnId: header.columnId,
                    length: header.length,
                    columnType: header.columnType
                })
            })

            this.defaultFieldsComplement.forEach(complement => {
                if (complement.create) defaultFields.splice(complement.position, 0, complement)
            })

            return defaultFields
        },

        returnEditedItemToDefault() {
            this.editedItem = lodash.cloneDeep(this.defaultItem)
        },

        setEditedItem(item) {
            this.defaultItem.forEach((defaultItem, index) => {
                if (defaultItem.edit !== false) {
                    if (defaultItem.name in item) {
                        if (item[defaultItem.name]) {
                            this.editedItem[index].value = item[defaultItem.name]
                        } else {
                            if (defaultItem.columnType === 'check') {
                                this.editedItem[index].value = 0
                            } else {
                                this.editedItem[index].value = null
                            }
                        }
                    }
                }
            })

            // remove defaultFieldsComplement if not editable
            this.editedItem.forEach((item, index) => {
                if (item.edit === false) {
                    this.editedItem.splice(index, 1)
                }
            })
        },

        clearTableData() {
            this.apiResult.items = []
            this.apiResult.headers = []
            this.headers = []
            this.editedItem = []
            this.defaultItem = []
            this.unsavedChanges = false
            this.editedItemsId = []
            this.serverSideFilteredItems = []

            this.$emit('setNoData', true)
        },

        clearOptions() {
            this.loading = true

            let requiredItems = []
            this.$emit('setFilterConditions', [])

            this.options.forEach(option => {
                if (option.required) {
                    requiredItems.push(option)
                }
            })

            if (requiredItems.length > 0) {
                this.options.forEach(option => {
                    if (!option.required) {
                        option.items = []
                    } else {
                        this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + option.label + ' para prosseguir')
                    }
                })
    
                this.clearTableData()
            }
            
            this.loading = false
        },

        closeTable() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            } else {
                this.dialogCloseTable = true
            }
        },

        setEditedRow(item) {
            let editedRow = this.editedItemsId.includes(item[this.getItemKey()])
            return editedRow
        },

        setSelectedRow(item) {
            return this.selected.find(i => i === item)
        },

        onResize () {
            let znapFiltersContainerHeight = this.$parent.$refs.znapFilters?.$el.clientHeight
            if (znapFiltersContainerHeight) this.znapFiltersContainerHeight = znapFiltersContainerHeight
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },

        saveSelectedDate(date, index, columnName) {
            this.$refs['menu' + index + columnName][0].save(date)
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        formatFloat(float) {
            if (float === undefined || float === null) {
                return ''
            } else {
                return this.$options.filters.floatFilter(float)
            }
        },

        formatCpfCnpj(cpfCnpj) {
            if (cpfCnpj === undefined || cpfCnpj === null) {
                return ''
            } else {
                return this.$options.filters.cpfCnpjFilter(cpfCnpj)
            }
        },

        formatCheckboxValue(check) {
            let value = null
            if (check === 1) value = true
            if (check === null || check === 0) value = false
            return value
        },

        setCheckboxValue(e, item, headerValue) {
            if (e) {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[headerValue] = 1
                        this.itemHasBeenEdited(item)
                    }
                })
            } else {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[headerValue] = 0
                        this.itemHasBeenEdited(item)
                    }
                })
            }
        },

        getOptions(header) {
            let option = this.options.find(option => option.column === header.columnId)
            return option ? option.items : []
        },

        getGroupInfoItems(header, item) {
            let groupInfo = this.apiResult.groupInfo[header.groupInfo].groups
            let found = groupInfo.find(g => g.value === item[g.columnId])

            return found ? found.items : []
        },

        itemHasBeenEdited(item) {
            let field = this.getItemKey()
            let id = item[field]

            let _item = this.itemsInitialState.find(_item => {
                return _item[field] === item[field]
            })

            if (lodash.isEqual(_item, item)) {
                this.editedItemsId.forEach((editedItemId, index) => {
                    if (editedItemId === id) {
                        this.editedItemsId.splice(index, 1)
                    }
                })

                this.setUnsavedChanges()
            } else {
                if (!this.editedItemsId.includes(id)) this.editedItemsId.push(id)

                this.setUnsavedChanges()
            }

            if (!this.showToolbar) {
                let editedItems = this.apiResult.items.filter(item => this.editedItemsId.includes(item[this.getItemKey()]))
                this.$emit('setEditedItems', editedItems)
            }
        },

        setUnsavedChanges() {
            if (this.editedItemsId.length > 0) {
                this.unsavedChanges = true
            } else {
                this.unsavedChanges = false
            }
        },

        backupSelect(index, columnId) {
            if (columnId) {
                this.backupSelectValue = this.apiResult.items[index][columnId]
            }
        },

        getItemKey() {
            return 'id_' + this.endpoint[1].replaceAll('-', '_')
        },

        setSelectedOption(item, columnId, name, e) {
            if (e) {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[columnId] = e.id
                        tableItem[name] = e.text
                    }
                })
            } else {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[columnId] = null
                    }
                })
            }
        },

        cancelSelectedOption(item, columnId) {
            this.apiResult.items.forEach(tableItem => {
                if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                    tableItem[columnId] = this.backupSelectValue
                }
            })
        },

        duplicateSelectedItem() {
            let item = this.selected[0]
            this.editedIndex = 0
            this.setEditedItem(item)

            if (this.unsavedChanges) {
                this.dialogEditItem = true
            } else {
                this.dialog = true
            }
        },

        editSelectedItem() {
            let item = this.selected[0]
            this.editedIndex = item[this.getItemKey()]
            this.setEditedItem(item)

            if (this.unsavedChanges) {
                this.dialogEditItem = true
            } else {
                this.dialog = true
            }
        },

        editSelectedItems() {
            if (this.unsavedChanges) {
                this.dialogEditItems = true
            } else {
                this.massReclassifyDialog = true
            }
        },

        editItem(item) {
            this.editedIndex = item[this.getItemKey()]
            this.setEditedItem(item)

            if (this.showToolbar) {
                if (this.unsavedChanges) {
                    this.dialogEditItem = true
                } else {
                    this.dialog = true
                }
            } else {
                this.$emit('editItem', this.editedItem)
            }
        },

        deleteItem (item) {
            this.editedIndex = item[this.getItemKey()]

            if (this.showToolbar) {
                this.dialogDelete = true
            } else {
                this.$emit('deleteItem', this.editedIndex)
            }
        },

        async deleteItemConfirm () {
            this.deleting = true
            try {
                const res = await this.$http.delete(this.endpoint[0] + this.endpoint[1] + `/delete/${this.editedIndex}`, {})
                if (res) {
                    if (this.selected.length > 0) {
                        this.selected = []
                    }

                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }

            this.closeDelete()
            this.deleting = false
            this.load()
        },

        massDeleteItem () {
            this.dialogMassDelete = true
        },

        async massDeleteItemConfirm() {
            this.massDeleting = true

            let payload = {
                delete: { items: [] },
            }

            payload.delete.items = this.selected

            try {
                const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', payload)
                if (res) {
                    if (this.selected.length > 0) {
                        this.selected = []
                    }

                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }
            
            this.closeMassDelete()
            this.massDeleting = false
            this.load()
        },

        close () {
            this.dialog = false

            this.repopulateFilters()

            if (this.editedIndex > -1) {
                this.returnEditedItemToDefault()
                this.editedIndex = -1
            }
        },

        repopulateFilters() {
            this.options.forEach(async o => {
                if (o.filteredBy) {
                    let foundCondition = this.filterConditions.find(f => o.filteredBy.includes(f.column))

                    if (foundCondition) {
                        let filter = {
                            conditions: [
                                {
                                    AndOr: 'AND',
                                    column: foundCondition.column,
                                    operator: '=',
                                    value: foundCondition.value
                                }
                            ]
                        }

                        let pathMethod = '/list-options'
                        if (o.endpoint[2]) pathMethod = o.endpoint[2]
    
                        try {
                            let res = await this.$http.post(o.endpoint[0] + o.endpoint[1] + pathMethod, { filter })
                            if (res) {
                                o.filteredItems = res.data.rows
                            }
                        } catch (err) {
                            this.$fnError(err)
                        }
                    }
                    
                }
            })
        },

        closeDelete () {
            this.dialogDelete = false

            if (this.editedIndex > -1) {
                this.returnEditedItemToDefault()
                this.editedIndex = -1
            }
        },

        closeMassDelete () {
            this.dialogMassDelete = false
        },

        async massSave(itemKey) {
            this.massSaving = true
            
            if (this.unsavedChanges) {
                this.backupPage = this.pagination.page

                let payload = {
                    edit: { items: [] },
                }

                this.apiResult.items.forEach(tableItem => {
                    if (this.editedItemsId.includes(tableItem[itemKey])) {
                        payload.edit.items.push(tableItem)
                    }
                })

                this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', payload)
                .then(res => {
                    this.$toast.success(res.data.msg)
                    this.load()
                    this.massSaving = false
                })
                .catch((err)=>{
                    this.$fnError(err)
                    this.massSaving = false
                })
            }
        },

        async save () {
            this.saving = true
            this.selected = []
            let object = {}
            let id = null
            this.backupPage = this.pagination.page

            this.editedItem.forEach(item => {
                if (item.label === 'ID') {
                    id = item.value
                }
                object[item.name] = item.value
            })

            if (this.endpoint[2]) {
                if (this.endpoint[2].conditions.length) {
                    this.endpoint[2].conditions.forEach(condition => {
                        object[condition.column] = condition.value
                    })
                }
            }

            if (this.editedIndex <= 0) {
                try {
                    const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/create', { ...object })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
                
            } else {
                try {
                    const res = await this.$http.put(this.endpoint[0] + this.endpoint[1] + `/edit/${id}`, { ...object })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            }

            this.close()
            this.saving = false
            this.load()
        },

        exportSheet(){
            this.exporting = true

            let payload = this.setPayload({}, this.endpoint[2])

            let endpoint = '/report'
            if (this.$route.path === '/vendor-contract' || this.$route.path === '/vendor-payment') endpoint = '/report-analyse'

            this.$http.post(this.endpoint[0] + this.endpoint[1] + endpoint, { ...payload }) 
            .then((res) => {
                window.open(res.data.token)
                this.$toast.success(res.data.msg)
                this.exporting = false
            })
            .catch((err)=>{
                this.exporting = false
                this.$fnError(err)
            })
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                this.$http.post(this.endpoint[0] + this.endpoint[1] + '/upload-spreadsheet',
                    body, { headers: { 'Content-Type': 'multipart/form-data' }}
                )
                .then((res)=>{
                    this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', {...res.data.id})
                    .then((res)=>{
                        this.$toast.success(res.data.msg)
                        this.$refs.uploader.value = null
                        this.importing = false
                        this.load()
                    }).catch((err)=>{
                        this.$refs.uploader.value = null
                        this.importing = false
                        this.$fnError(err)
                    })
                })
                .catch((err)=>{
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                })
            }
        },

        newFilterConstructor() {
            this.filterConstructors.push({...this.filterConstructor})
        },

        removeFilterConstructor(index) {
            this.filterConstructors.splice(index, 1)
        },
    },
}
</script>

<style>
.edited-row {
    background-color: #fdebb9b6;
}

.edited-row:hover {
    background-color: #fad777b6 !important;
}

.sum-row {
    /* background-color: var(--v-neutral-lighten5); */
    background-color: #DDD;
    font-weight: bold;
}

.sum-row:hover {
    background-color: #DDD !important;
}

.selected-row {
    background-color: #eee !important;
}

.selected-row:hover {
    background-color: #ddd !important;
}

.v-small-dialog__activator__content {
    width: 100%;
}

.v-data-footer {
    margin-right: 0 !important; 
}
</style>
